/**=====================
     39. Table  CSS Start
==========================**/
.pills-component {
	width: 20%;
}
.table{
	margin-bottom: $table-b-margin;
	th,td{
		padding: $table-padding;
	}
	th{
		color: $table-heading-color;
		font-weight: 500;
	}
	td{
		color: $table-row-color;
	}
	[class*='bg-']{
		color: $white;
	}
	.bg-light{
		color: $theme-body-font-color;
	}
	tfoot{
		font-weight: $table-footer-font-weight;
	}
	.double {
		border: 0;
		th,
		td {
			border-bottom-style: double;
			border-color: $light-gray;
		}
		&:last-child {
			th,
			td {
				border-bottom: none;
			}
		}
	}
	thead {
		.double {
			th {
				border-top: 1px double $light-gray;
				border-bottom-style: double !important;
				border-color: $light-gray !important;
			}
		}
	}
	.dotted {
		border: 0;
		th,
		td {
			border-bottom-style: dotted;
			border-color: $light-gray;
		}
		&:last-child {
			th,
			td {
				border-bottom: none;
			}
		}
	}
	thead {
		.dotted {
			th {
				border-top: 1px dotted $light-gray;
				border-bottom-style: dotted !important;
				border-color: $light-gray !important;
			}
		}
	}
	.dashed {
		border: 0;
		th,
		td {
			border-bottom-style: dashed;
			border-color: $light-gray;
		}
		&:last-child {
			th,
			td {
				border-bottom: none;
			}
		}
	}
	thead {
		.dashed {
			th {
				border-top: 1px dashed $light-gray;
				border-bottom-style: dashed !important;
				border-color: $light-gray !important;
			}
		}
	}
}
.table.table-primary{
	th{
		color: $white;
	}
}
.table-inverse {
	color: $white;
	background-color: $table-inverse-bg-color;
	th,td{
		border-color: $white;
		color: $white;
	}
	&.table-bordered {
		border: 0;
	}
}
.table[class*='bg-']{
	th,td{
		color: $white;
	}
}
.table-hover {
	tbody {
		tr:hover {
			background-color: $table-hover-color;
		}
		tr{
			td {
				a {
				svg{
					width: 20px;
					height: 20px;
					// color: #888888;
					margin: 5px;
				}
				
			}
		}
			
		}
	}
}
.table-responsive.table-border-radius{
	.table-styling.table-primary{
		--bs-table-bg: unset;
	}
}
table.dashed{
	tbody{
	  border-top: 2px dashed $light-gray !important;
	}
	.dashed{
	  border: 2px dashed $light-gray;
	}
  }
  table.dotted{
	tbody{
	  border-top: 2px dotted $light-gray !important;
	}
	.dotted{
	  border: 2px dotted $light-gray;
	}
  }
.table > :not(:first-child) {
    border-top: 2px solid $light-gray;
}
//Sizing class
.table-xl{
	td,th{
		padding: $table-xl-padding;
		font-size: $table-xl-font;
	}
}
.table-lg{
	td,th{
		padding: $table-lg-padding;
		font-size: $table-lg-font;
	}
}
.table-de{
	td,th{
		padding: $table-de-padding;
	}
}
.table-sm{
	th,td{
		padding: $table-sm-padding;
		font-size: $table-sm-font;
	}
}
.table-xs{
	th,td{
		padding: $table-xs-padding;
		font-size: $table-xs-font;
	}
}

//Table Border
.table-border-horizontal {
	tr,th,td{
		border-top: 1px solid $horizontal-border-color;
		padding: $horizontal-padding;
	}
}
.table-border-vertical{
	tr,th,td{
		border-top: 0;
		border-right: 1px solid $horizontal-border-color;
	}
	tr{
		td{
			&:last-child{
				border-right: none;
			}
		}
	}
}
.table-bordernone{
	td{
		border: none !important;
	}
	thead {
		th {
			border: none !important;
		}
	}
	.bd-t-none{
		border-top: none;
	}
	.u-s-tb{
		padding: 11px;
	}
}
.table-striped{
	tbody{
		tr{
			&:nth-of-type(odd){
				background-color: rgba(245, 247, 250, 0.5);
				*{
					--bs-table-accent-bg: unset;
				}
			}
		}
	}
}
.table-bordered{
	td, th  {
		border-color: $horizontal-border-color;
	}
}
/**=====================
     39.Table CSS Ends
==========================**/
